window.updateDesk = function updateDesk(id) {
  if (!window.auth0) {
    console.warn("No window.auth0");
    return;
  }

  const name = prompt("Hva er det nye navnet som skal stå her?");

  if (!name) return;

  const sure = confirm(
    `Sikker på at du vil oppdatere til ${name}? Det vil bli synlig for alle andre.`
  );

  if (!sure) return;

  window.auth0.getTokenSilently().then((token) => {
    if (!token) {
      console.warn("No token");
      return;
    }

    const url = window.origin.includes("localhost")
      ? `http://localhost:1235/entur-floorplan/europe-west1/kart/desks/${id}`
      : `https://europe-west1-entur-floorplan.cloudfunctions.net/kart/desks/${id}`;

    return fetch(url, {
      method: "PATCH",
      body: JSON.stringify({
        name,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(
            `updateDesk request failed with status code ${res.status}`
          );
        }
        return res.text();
      })
      .then((map) => {
        document.getElementById("main").innerHTML = map;
      })
      .then(() => {
        const hashValue = decodeURIComponent(
          (window.location.hash || "").replace("#", "")
        );
        document
          .getElementById("search")
          .addEventListener("input", function (e) {
            window.search(e.currentTarget.value);
          });
        document.getElementById("search").value = hashValue;
        window.search(hashValue);
      })
      .catch(console.error);
  });
};
